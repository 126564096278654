<template>
    <div class="z-10 top-2">
        <a href="/">
                    <svg width="62" height="62" viewBox="0 0 170.79 170.79" fill="none" class="text-primary-900
            " version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                enable-background="new 0 0 170.79 170.79" xml:space="preserve">
            <path fill="black" d="M85.395,16.813c37.816,0,68.582,30.765,68.582,68.582s-30.766,68.582-68.582,68.582
                c-37.816,0-68.582-30.765-68.582-68.582S47.579,16.813,85.395,16.813 M85.395,0C38.233,0,0,38.233,0,85.395
                s38.233,85.395,85.395,85.395s85.395-38.233,85.395-85.395S132.558,0,85.395,0L85.395,0z"/>
            </svg>
        </a>
    </div>
</template>
<script>
export default {
    setup() {
        
    },
}
</script>