import { createRouter, createWebHistory } from "vue-router";
import adminGuard from "../guards/AdminGuard";
// import { useStore } from "vuex";

// const store = useStore();

const routes = [
  //Admin
  {
    path: "/",
    name: "AdminMyAccount",
    component: () => import("../views/Myaccount.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/applications",
    name: "Applications",
    component: () => import("../views/Applications.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue"),
  },
  {
    path: "/my-wallet",
    name: "AdminMyWallet",
    component: () => import("../views/Mywallets.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/collections",
    name: "Admin_collections",
    component: () => import("../views/Collections.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/register/:uuid",
    name: "Register",
    component: () => import("../views/Register.vue"),
  },
  {
    path: "/collection/detail/:ref",
    name: "Collection_detail",
    component: () => import("../views/Collectiondetail.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/settings",
    name: "Admin_settings",
    component: () => import("../views/Settings"),
    beforeEnter: adminGuard,
  },
  {
    path: "/artwork/:collection/:artid?",
    name: "Artist_detail",
    component: () => import("../views/Artistdetail.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/profile/:username",
    name: "admins_profile",
    component: () => import("../views/Profile.vue"),
    beforeEnter: adminGuard,
  },
  // {
  //   path: "/user-profile/:username",
  //   name: "clients_profile",
  //   component: () => import("../views/Profile.vue"),
  //   //beforeEnter: adminGuard,
  // },
  {
    path: "/vault-category",
    name: "VaultCategory",
    component: () => import("../views/VaultCategory.vue"),
    eforeEnter: adminGuard,
  },
  {
    path: "/vault-category/:id/edit",
    name: "VaultCategoryEdit",
    component: () => import("../views/VaultCategoryEdit.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/vaults/",
    name: "Vaults",
    component: () => import("../views/Vaults.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/vaults/create",
    name: "Vaults-create",
    component: () => import("../views/VaultCreation.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/vault/edit/:dbref",
    name: "Vaults-edit",
    component: () => import("../views/VaultEdit.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/vault/edit/step2/:dbref",
    name: "VaultEditStep2",
    component: () => import("../views/VaultEditStep2.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/nfts/",
    name: "nfts",
    component: () => import("../views/Nfts.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/nfts-create/",
    name: "nfts-create",
    component: () => import("../views/NftsCreate.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/nft-mint/:dbref",
    name: "Mint",
    component: () => import("../views/MintNft.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/affiliate-profile/:username",
    name: "affiliate_profile",
    component: () => import("../views/AffiliateProfile.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/affiliates/",
    name: "affiliates",
    component: () => import("../views/Affiliates.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/allowed_list/",
    name: "AllowedList",
    component: () => import("../views/AllowedList.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/allowed_list_user/:username",
    name: "AllowedListUser",
    component: () => import("../views/AllowedListUser.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/members/",
    name: "Members",
    component: () => import("../views/Clients.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/email/",
    name: "email",
    component: () => import("../views/Email.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/email/create",
    name: "email-create",
    component: () => import("../views/EmailTemplate.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/team/",
    name: "team",
    component: () => import("../views/Team.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/user-profile/:id",
    name: "UserProfile",
    component: () => import("../views/UserProfile.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/user-wallet/:id",
    name: "UserWallet",
    component: () => import("../views/UserWallet.vue"),
    beforeEnter: adminGuard,
  },
  {
    path: "/my-wallet/",
    name: "MyWallet",
    component: () => import("../views/MyWallet.vue"),
    beforeEnter: adminGuard,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
export default router;
