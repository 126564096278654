import { getCurrentUser } from "../firebase/firebase";

const adminGuard = async (to, from, next) => {
  if (!(await getCurrentUser())) {
    next("/login");
  } else {
    next();
  }
};

export default adminGuard;
