<template>
  <div class="header bg-white">
    <header>
      <Popover class="relative overflow-visible">
        <div
          class="
            flex
            justify-between
            text-center
            items-center
            
            px-4
            py-6
            
          "
        >
          <div
            class="flex"
          >
            <logo />
          </div>

          <div
            class="
              flex
              justify-end
             
              lg:w-0
              md:m-0
              mt-4
            "
          >
            <div
              class="
                whitespace-nowrap
                text-3xl
                font-medium
                text-gray-900
                hover:text-gray-900
              "
            >
              {{ currentRouteName }}
            </div>
          </div>
        </div>
      </Popover>
    </header>
  </div>
</template>
<script>
import { ref, computed } from "vue";
import { Popover } from "@headlessui/vue";
import slugify from "slugify";

import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import Logo from "./Logo.vue";
export default {
  components: {
    Popover,
    Logo,
  },
  methods: {
    closeMenu() {
      this.showMenu = false;
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },

  setup() {
    const hovered = ref(null);
    const route = useRoute();
    const store = useStore();
    const router = useRouter();

    const currentRouteName = computed(() => {
      if ("Artist,Artwork,Series".indexOf(route.name) !== -1) {
        return store.getters["collection/getName"];
      } else {
        return "";
      }
    });

    const goFractionalize = () => {
      router.push({ path: "/fractionalize" });
    };

    return {
      key: 0,
      goFractionalize,
      hovered, // access a state in computed function
      logo: `<svg width="183" height="51" viewBox="0 0 183 51" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2593_13223)">
<path d="M13.2757 21.1068C16.7044 21.1068 19.3355 22.2389 21.1694 24.503C23.0033 26.7275 23.9202 29.9649 23.9202 34.2152V48.2771H18.1793V34.2152C18.1793 31.5538 17.7209 29.5875 16.8039 28.3164C15.887 27.0057 14.3522 26.3502 12.1993 26.3502C10.4053 26.3502 8.87043 27.1049 7.59468 28.6144C6.3588 30.0841 5.74087 32.0305 5.74087 34.4536V48.2771H0V21.5835H5.74087V24.9202C6.25914 23.9271 7.17608 23.0532 8.4917 22.2985C9.80731 21.5041 11.402 21.1068 13.2757 21.1068Z" fill="#0A0A0A"/>
<path d="M32.6626 48.2771V21.5835H38.4035V48.2771H32.6626Z" fill="#0A0A0A"/>
<path d="M77.0513 21.1068C80.2805 21.1068 82.7722 22.2389 84.5264 24.503C86.3203 26.7672 87.2174 30.0046 87.2174 34.2152V48.2771H81.4765V34.2152C81.4765 31.5935 81.038 29.6272 80.1609 28.3164C79.3237 27.0057 77.9284 26.3502 75.9748 26.3502C74.1808 26.3502 72.7257 27.085 71.6093 28.5547C70.533 30.0246 69.9948 31.9908 69.9948 34.4536V48.2771H64.2539V34.2152C64.2539 31.5935 63.8153 29.6272 62.9382 28.3164C62.1011 27.0057 60.7057 26.3502 58.7523 26.3502C56.9582 26.3502 55.5031 27.085 54.3868 28.5547C53.3105 30.0246 52.7721 31.9908 52.7721 34.4536V48.2771H47.0312V21.5835H52.7721V24.9202C53.3303 24.1257 53.8486 23.51 54.327 23.073C54.8054 22.5964 55.523 22.1594 56.4798 21.7622C57.4766 21.3253 58.5927 21.1068 59.8286 21.1068C63.6161 21.1068 66.3669 22.6163 68.0811 25.6352C69.3968 23.9667 70.7124 22.795 72.028 22.1197C73.3835 21.4444 75.0579 21.1068 77.0513 21.1068Z" fill="#0A0A0A"/>
<path d="M101.126 9.36438V24.9202C103.399 22.3778 106.269 21.1068 109.738 21.1068C113.446 21.1068 116.595 22.4772 119.186 25.2182C121.818 27.9193 123.133 31.1566 123.133 34.9302C123.133 38.7833 121.818 42.0605 119.186 44.7616C116.595 47.423 113.446 48.7536 109.738 48.7536C106.269 48.7536 103.399 47.4825 101.126 44.9403V48.2771H95.3857V9.36438H101.126ZM103.459 41.0078C105.053 42.6761 106.987 43.5103 109.259 43.5103C111.532 43.5103 113.446 42.6761 115 41.0078C116.595 39.3394 117.392 37.3136 117.392 34.9302C117.392 32.5469 116.595 30.521 115 28.8527C113.446 27.1844 111.532 26.3502 109.259 26.3502C106.987 26.3502 105.053 27.1844 103.459 28.8527C101.904 30.521 101.126 32.5469 101.126 34.9302C101.126 37.3136 101.904 39.3394 103.459 41.0078Z" fill="#0A0A0A"/>
<path d="M141.199 48.7537C137.771 48.7537 135.139 47.6415 133.306 45.4169C131.472 43.1529 130.555 39.8955 130.555 35.6452V21.5835H136.296V36.1219C136.296 38.5451 136.754 40.3921 137.671 41.6632C138.588 42.8946 140.123 43.5104 142.276 43.5104C144.109 43.5104 145.644 42.7357 146.88 41.1866C148.116 39.6374 148.734 37.5519 148.734 34.9302V21.5835H154.475V48.2771H148.734V44.9404C148.216 45.9333 147.299 46.8271 145.983 47.6216C144.668 48.3763 143.073 48.7537 141.199 48.7537Z" fill="#0A0A0A"/>
<path d="M174.404 31.5935C180.105 32.9044 182.955 35.8041 182.955 40.2928C182.955 42.7953 181.998 44.841 180.085 46.4299C178.21 47.9791 175.998 48.7536 173.446 48.7536C169.419 48.7536 165.892 47.2443 162.862 44.2253L165.732 40.0544C168.084 42.3186 170.536 43.4705 173.088 43.5103C174.404 43.5103 175.419 43.2124 176.137 42.6166C176.855 41.981 177.213 41.3057 177.213 40.5907C177.213 39.121 175.978 38.0286 173.506 37.3136C169.998 36.3602 167.587 35.2877 166.27 34.0961C164.955 32.8647 164.296 31.0971 164.296 28.7932C164.296 26.7275 165.095 24.94 166.689 23.4305C168.323 21.8814 170.556 21.1068 173.387 21.1068C176.257 21.1068 179.891 22.795 182.162 24.6222L179.472 28.7932C177.877 27.6808 176.137 26.3502 173.387 26.3502C172.35 26.3502 171.532 26.5686 170.936 27.0057C170.337 27.4425 170.038 27.9788 170.038 28.6144C170.038 29.3691 170.337 29.9649 170.936 30.4019C171.573 30.7991 172.729 31.1963 174.404 31.5935Z" fill="#0A0A0A"/>
<path d="M87.3365 3.49738H47.0444V9.5578H87.3365V3.49738Z" fill="#F20D19"/>
</g>
<defs>
<clipPath id="clip0_2593_13223">
<rect width="182.955" height="50" fill="white" transform="translate(0 0.372375)"/>
</clipPath>
</defs>
</svg>
`,
      logo_hover: `<svg width="183" height="51" viewBox="0 0 183 51" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_2593_13223)">
<path d="M13.2757 21.1068C16.7044 21.1068 19.3355 22.2389 21.1694 24.503C23.0033 26.7275 23.9202 29.9649 23.9202 34.2152V48.2771H18.1793V34.2152C18.1793 31.5538 17.7209 29.5875 16.8039 28.3164C15.887 27.0057 14.3522 26.3502 12.1993 26.3502C10.4053 26.3502 8.87043 27.1049 7.59468 28.6144C6.3588 30.0841 5.74087 32.0305 5.74087 34.4536V48.2771H0V21.5835H5.74087V24.9202C6.25914 23.9271 7.17608 23.0532 8.4917 22.2985C9.80731 21.5041 11.402 21.1068 13.2757 21.1068Z" fill="#0A0A0A"/>
<path d="M32.6626 48.2771V21.5835H38.4035V48.2771H32.6626Z" fill="#0A0A0A"/>
<path d="M77.0513 21.1068C80.2805 21.1068 82.7722 22.2389 84.5264 24.503C86.3203 26.7672 87.2174 30.0046 87.2174 34.2152V48.2771H81.4765V34.2152C81.4765 31.5935 81.038 29.6272 80.1609 28.3164C79.3237 27.0057 77.9284 26.3502 75.9748 26.3502C74.1808 26.3502 72.7257 27.085 71.6093 28.5547C70.533 30.0246 69.9948 31.9908 69.9948 34.4536V48.2771H64.2539V34.2152C64.2539 31.5935 63.8153 29.6272 62.9382 28.3164C62.1011 27.0057 60.7057 26.3502 58.7523 26.3502C56.9582 26.3502 55.5031 27.085 54.3868 28.5547C53.3105 30.0246 52.7721 31.9908 52.7721 34.4536V48.2771H47.0312V21.5835H52.7721V24.9202C53.3303 24.1257 53.8486 23.51 54.327 23.073C54.8054 22.5964 55.523 22.1594 56.4798 21.7622C57.4766 21.3253 58.5927 21.1068 59.8286 21.1068C63.6161 21.1068 66.3669 22.6163 68.0811 25.6352C69.3968 23.9667 70.7124 22.795 72.028 22.1197C73.3835 21.4444 75.0579 21.1068 77.0513 21.1068Z" fill="#0A0A0A"/>
<path d="M101.126 9.36438V24.9202C103.399 22.3778 106.269 21.1068 109.738 21.1068C113.446 21.1068 116.595 22.4772 119.186 25.2182C121.818 27.9193 123.133 31.1566 123.133 34.9302C123.133 38.7833 121.818 42.0605 119.186 44.7616C116.595 47.423 113.446 48.7536 109.738 48.7536C106.269 48.7536 103.399 47.4825 101.126 44.9403V48.2771H95.3857V9.36438H101.126ZM103.459 41.0078C105.053 42.6761 106.987 43.5103 109.259 43.5103C111.532 43.5103 113.446 42.6761 115 41.0078C116.595 39.3394 117.392 37.3136 117.392 34.9302C117.392 32.5469 116.595 30.521 115 28.8527C113.446 27.1844 111.532 26.3502 109.259 26.3502C106.987 26.3502 105.053 27.1844 103.459 28.8527C101.904 30.521 101.126 32.5469 101.126 34.9302C101.126 37.3136 101.904 39.3394 103.459 41.0078Z" fill="#0A0A0A"/>
<path d="M141.199 48.7537C137.771 48.7537 135.139 47.6415 133.306 45.4169C131.472 43.1529 130.555 39.8955 130.555 35.6452V21.5835H136.296V36.1219C136.296 38.5451 136.754 40.3921 137.671 41.6632C138.588 42.8946 140.123 43.5104 142.276 43.5104C144.109 43.5104 145.644 42.7357 146.88 41.1866C148.116 39.6374 148.734 37.5519 148.734 34.9302V21.5835H154.475V48.2771H148.734V44.9404C148.216 45.9333 147.299 46.8271 145.983 47.6216C144.668 48.3763 143.073 48.7537 141.199 48.7537Z" fill="#0A0A0A"/>
<path d="M174.404 31.5935C180.105 32.9044 182.955 35.8041 182.955 40.2928C182.955 42.7953 181.998 44.841 180.085 46.4299C178.21 47.9791 175.998 48.7536 173.446 48.7536C169.419 48.7536 165.892 47.2443 162.862 44.2253L165.732 40.0544C168.084 42.3186 170.536 43.4705 173.088 43.5103C174.404 43.5103 175.419 43.2124 176.137 42.6166C176.855 41.981 177.213 41.3057 177.213 40.5907C177.213 39.121 175.978 38.0286 173.506 37.3136C169.998 36.3602 167.587 35.2877 166.27 34.0961C164.955 32.8647 164.296 31.0971 164.296 28.7932C164.296 26.7275 165.095 24.94 166.689 23.4305C168.323 21.8814 170.556 21.1068 173.387 21.1068C176.257 21.1068 179.891 22.795 182.162 24.6222L179.472 28.7932C177.877 27.6808 176.137 26.3502 173.387 26.3502C172.35 26.3502 171.532 26.5686 170.936 27.0057C170.337 27.4425 170.038 27.9788 170.038 28.6144C170.038 29.3691 170.337 29.9649 170.936 30.4019C171.573 30.7991 172.729 31.1963 174.404 31.5935Z" fill="#0A0A0A"/>
<path d="M87.3365 3.49738H47.0444V9.5578H87.3365V3.49738Z" fill="#F20D19"/>
</g>
<defs>
<clipPath id="clip0_2593_13223">
<rect width="182.955" height="50" fill="white" transform="translate(0 0.372375)"/>
</clipPath>
</defs>
</svg>
`,

      currentRouteName,
      slugify,
    };
  },
};
</script>
