<template>
  <Header v-if="routeName !== 'Login'"/>
  <div class="body ">
    <router-view v-if="loaded" />
    <Modal />
    <toast />
  </div>
  <Footer v-if="routeName !== 'Login'" />
  <loading />
</template>

<script>
import Header from "@/components/Layouts/Header.vue";
import Footer from "@/components/Layouts/Footer.vue";
import Toast from "./components/Shared/Toast.vue";
import Modal from './components/Modals/Modal.vue';
import Loading from './components/Shared/Loading.vue';
import { useStore } from 'vuex';
import { onMounted, ref, computed } from '@vue/runtime-core';
import { useRoute } from 'vue-router';

export default {
  components: {
    Header,
    Footer,
    Toast,
    Modal,
    Loading,
  },
  setup() {
    const store = useStore();
    const loaded = ref(false);
    const route = useRoute();
    const routeName = computed(() => route.name);

    onMounted(async() => {
      await store.dispatch('admin/getAdmin', {});
      loaded.value = true;
    });

    return {
      loaded,
      routeName
    }
  }
};
</script>

<style scoped>
.body {
  min-height: calc(100vh - 400px);
  position: relative;
}
</style>
