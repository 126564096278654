// Works for web3 1.0 and pre-1.0 versions
import axios from "axios";
import Web3 from "web3";
import GOGAuctionV2 from "../contracts/GOGAuctionV2.json";
import FERC721 from "../contracts/FERC721.json";
import USDCAbi from "../contracts/USDCAbi.json";
import VaultFactory from "../contracts/VaultFactory.json";
import BasketFactory from "../contracts/BasketFactory.json";
//import * as ethUtil  from 'ethereumjs-util';
//import * as sigUtil from '@metamask/eth-sig-util';

const addresses = {
  ropsten: {
    auction: "0xC3dC1B68B3D077Fe23178206462f792943f75b3A",
    ERC721: "0xBcA770B20C37331887d7074481bc3B61B75e2641",
    USDC: "0x07865c6e87b9f70255377e024ace6630c1eaa37f",
    vaultFactory: "0x458556c097251f52ca89cB81316B4113aC734BD1"
  },
  goerli: {
    ERC721: process.env.VUE_APP_ERC721,
    USDC: "0x07865c6e87b9f70255377e024ace6630c1eaa37f",
  },
  rinkeby: {
    auction: "0xE1133Ff991392Af52025eD60a99f258A71054F47",
    ERC721: "0xaB0e20f3d25Fa0E8F5F0895f9f919378466B2063",
    USDC: "0xeb8f08a975ab53e34d8a0330e0d34de942c95926",
    vaultFactory: "0x458556c097251f52ca89cB81316B4113aC734BD1",
    settings: "0x1C0857f8642D704ecB213A752A3f68E51913A779",
    basketFactory: "0xb11a56387eb0F479f89bDBc9bf8cA7a2668041dA"
  },
  mainnet: {
    auction: "0xE1133Ff991392Af52025eD60a99f258A71054F47",
    ERC721: process.env.VUE_APP_ERC721,
    USDC: "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
  },
};

let currNetwork = process.env.VUE_APP_NETWORK;
let decimals = Math.pow(10, 6);
let decimalsEth = Math.pow(10, 18);

const testAuthentication = async () => {
  try {
    const url = `https://api.pinata.cloud/data/testAuthentication`;
    const response = await axios.get(url, {
      headers: {
        pinata_api_key: process.env.VUE_APP_PINATA_API_KEY,
        pinata_secret_api_key: process.env.VUE_APP_PINATA_API_SECRET,
      },
    });
    return response;
  } catch (error) {
    console.log(error);
  }
};

const pinFile = async (image, name) => {
  const url = `https://api.pinata.cloud/pinning/pinFileToIPFS`;
  //we gather a local file from the API for this example, but you can gather the file from anywhere
  let data = new FormData();
  data.append("file", image);
  const metadata = {
    name: name,
  };
  data.append("metadata", metadata);
  const results = await axios.post(url, data, {
    headers: {
      "Content-Type": `multipart/form-data; boundary= ${data._boundary}`,
      pinata_api_key: process.env.VUE_APP_PINATA_API_KEY,
      pinata_secret_api_key: process.env.VUE_APP_PINATA_API_SECRET,
    },
  });
  return results;
};

const pinJson = async (json) => {
  const url = `https://api.pinata.cloud/pinning/pinJSONToIPFS`;

  const results = await axios.post(url, json, {
    headers: {
      pinata_api_key: process.env.VUE_APP_PINATA_API_KEY,
      pinata_secret_api_key: process.env.VUE_APP_PINATA_API_SECRET,
    },
  });
  return results.data;
};

const addOperatorERC721 = async (account) => {
  const accounts = await window.ethereum.send("eth_requestAccounts");
  window.web3 = new Web3(window.ethereum);
  // Create contract object
  const tokenContractERC721 = new window.web3.eth.Contract(
    FERC721,
    addresses[currNetwork].ERC721
  );
  const receipt = await tokenContractERC721.methods
    .addOperator(account)
    .send({ from: accounts.result[0] });

  return receipt;
};

const addWhitelistERC721 = async (account, price, qty) => {
  const accounts = await window.ethereum.send("eth_requestAccounts");
  window.web3 = new Web3(window.ethereum);
  // Create contract object
  const tokenContractERC721 = new window.web3.eth.Contract(
    FERC721,
    addresses[currNetwork].ERC721
  );
  const currentQty = await tokenContractERC721.methods
    .getWhiteListForAddress(account)
    .call()
  console.log("QTY:",currentQty)
  console.log(price)
  console.log(accounts.result)
  const receipt = await tokenContractERC721.methods
    .addToWhiteList(window.web3.utils.toWei(price, "ether"), qty, account)
    .send({ from: accounts.result[0] });

  return receipt;
};

const unlockNftNow = async () => {
  const accounts = await window.ethereum.send("eth_requestAccounts");
  window.web3 = new Web3(window.ethereum);
  // Create contract object
  const tokenContractERC721 = new window.web3.eth.Contract(
    FERC721,
    addresses[currNetwork].ERC721
  );

  const receipt = await tokenContractERC721.methods
    .lockTrades(21)
    .send({ from: accounts.result[0] });

  return receipt;
};

const getCurrentPrice = async () => {
  const accounts = await window.ethereum.send("eth_requestAccounts");
  window.web3 = new Web3(window.ethereum);

  const tokenContractERC721 = new window.web3.eth.Contract(
    FERC721,
    addresses[currNetwork].ERC721
  );
  const price = await tokenContractERC721.methods
    .decideMintPrice()
    .call({ from: accounts[0] });
  return window.web3.utils.fromWei(price, "ether");
}

const getGasFee = async (fromAddress, toAddress, amount) => {
  const web3 = new Web3(window.ethereum);
  const gasAmount = await web3.eth.estimateGas({
    to: toAddress,
    from: fromAddress,
    value: web3.utils.toWei(`${amount}`, 'ether'),
  });
  const gasPrice = await web3.eth.getGasPrice();
  const price = gasAmount * gasPrice
  return web3.utils.fromWei(price.toString(), 'ether');
}

const transfer = async (address, value) => {
  const web3 = new Web3(window.ethereum);
  //BN = window.web3.utils.BN;
  const accounts = await web3.eth.getAccounts();
  console.log(accounts);
  // Create contract object
  const receipt = web3.eth.sendTransaction({
    from: accounts[0],
    to: address,
    value: web3.utils.toWei(value.toString(), "ether"),
    data: ""
})
  console.log("RECEIPT2", receipt)
  // const receipt = await tokenContractERC721.methods
  //   .mintWithToken(minter, id, metadataUri, web3.utils.keccak256(token))
  //   .send({ from: accounts[0], value: web3.utils.toWei(value.toString(), "ether")});

  return receipt;
};

const addMinterERC721 = async (account) => {
  const accounts = await window.ethereum.send("eth_requestAccounts");
  window.web3 = new Web3(window.ethereum);
  // Create contract object
  const tokenContractERC721 = new window.web3.eth.Contract(
    FERC721,
    addresses[currNetwork].ERC721
  );
  const receipt = await tokenContractERC721.methods
    .grantRole(window.web3.utils.fromAscii("MINTER_ROLE"), account)
    .send({ from: accounts.result[0] });

  return receipt;
};

const addOperatorGOGAuction = async (account) => {
  const accounts = await window.ethereum.send("eth_requestAccounts");
  window.web3 = new Web3(window.ethereum);
  // Create contract object
  const tokenContractERCAuction = new window.web3.eth.Contract(
    GOGAuctionV2.abi,
    addresses[currNetwork].auction
  );
  const receipt = await tokenContractERCAuction.methods
    .addOperator(account)
    .send({ from: accounts.result[0] });

  return receipt;
};

const mintNft = async (metadataUri) => {
  const accounts = await window.ethereum.send("eth_requestAccounts");
  window.web3 = new Web3(window.ethereum);
  console.log(metadataUri);
  // Create contract object
  const tokenContractERC721 = new window.web3.eth.Contract(
    FERC721,
    addresses[currNetwork].ERC721
  );
  const receipt = await tokenContractERC721.methods
    .adminMint(metadataUri)
    .send({ from: accounts.result[0] });

  return receipt;
};

const getNftSupply = async (metadataUri) => {
  //const accounts = await window.ethereum.send("eth_requestAccounts");
  window.web3 = new Web3(window.ethereum);
  console.log(metadataUri);
  // Create contract object
  const tokenContractERC721 = new window.web3.eth.Contract(
    FERC721,
    addresses[currNetwork].ERC721
  );
  const supply = await tokenContractERC721.methods.totalSupply().call();

  return supply;
};

const mintBasket = async () => {
  const accounts = await window.ethereum.send("eth_requestAccounts");
  window.web3 = new Web3(window.ethereum);
  // Create contract object
  const basketFactoryContract = new window.web3.eth.Contract(
    BasketFactory,
    addresses[currNetwork].basketFactory
  );
  const receipt = await basketFactoryContract.methods
    .createBasket()
    .send({ from: accounts.result[0] });

  return receipt;
};

const approveBasket = async (basketaddress) => {
  const accounts = await window.ethereum.send("eth_requestAccounts");
  window.web3 = new Web3(window.ethereum);
  // Create contract object
  const basketContract = new window.web3.eth.Contract(
    FERC721,
    basketaddress
  );
  const receipt = await basketContract.methods
    .setApprovalForAll(addresses[currNetwork].vaultFactory, true)
    .send({ from: accounts.result[0] });

  return receipt;
};

const mintVault = async (name, ticker, basketaddress, id, supply, listPrice, fee) => {
  const accounts = await window.ethereum.send("eth_requestAccounts");
  window.web3 = new Web3(window.ethereum);
  // Create contract object
  const vaultFactoryContract = new window.web3.eth.Contract(
    VaultFactory,
    addresses[currNetwork].vaultFactory
  );
  const receipt = await vaultFactoryContract.methods
    .mint(name, ticker, basketaddress, 0, supply, listPrice, fee)
    .sendAsync({ from: accounts.result[0] });

  return receipt;
};

const approveNFT = async (nftAddress, id, basketaddress) => {
  const accounts = await window.ethereum.send("eth_requestAccounts");
  window.web3 = new Web3(window.ethereum);
  // Create contract object
  const nftContract = new window.web3.eth.Contract(
    FERC721,
    nftAddress
  );
  const receipt = await nftContract.methods
    .approve(basketaddress, id)
    .send({ from: accounts.result[0] });

  return receipt;
};

const transferNFT = async (nftAddress, id, basketaddress) => {
  console.log('Here');

  const accounts = await window.ethereum.send("eth_requestAccounts");
  window.web3 = new Web3(window.ethereum);
  // Create contract object
  const nftContract = new window.web3.eth.Contract(
    FERC721,
    nftAddress
  );
  
  try {
   
    const receipt = await nftContract.methods
      .safeTransferFrom(accounts.result[0], basketaddress, id)
      .send({ from: accounts.result[0] });

    return receipt;
  } catch(error) {
    console.log(error);
  }
};

const cancelAuction = async (id) => {
  try {
    const accounts = await window.ethereum.send("eth_requestAccounts");
    window.web3 = new Web3(window.ethereum);
    // Create contract object
    const tokenContractERCAuction = new window.web3.eth.Contract(
      GOGAuctionV2.abi,
      addresses[currNetwork].auction
    );

    const receipt = await tokenContractERCAuction.methods
      .auctionCancel(id)
      .send({ from: accounts.result[0] });

    return receipt;
  } catch (error) {
    return error.message;
  }
};

const toggleAuction = async (id) => {
  const accounts = await window.ethereum.send("eth_requestAccounts");
  window.web3 = new Web3(window.ethereum);
  // Create contract object
  const tokenContractERCAuction = new window.web3.eth.Contract(
    GOGAuctionV2.abi,
    addresses[currNetwork].auction
  );
  let receipt;
  receipt = await tokenContractERCAuction.methods
      .toggleAuction(id)
      .send({ from: accounts.result[0] });

  return receipt;
};

const createAuction = async (auctionId, time, artwork_id, minimumBid, artist_address, isEth) => {

  try {
    const accounts = await window.ethereum.send("eth_requestAccounts");
    window.web3 = new Web3(window.ethereum);
    // Create contract object
    const auctionContract = new window.web3.eth.Contract(
      GOGAuctionV2.abi,
      addresses[currNetwork].auction
    );

    let receipt;

    if (!isEth) {
      receipt = await auctionContract.methods
        .createAuction(
          auctionId,
          time,
          artwork_id,
          minimumBid * decimals,
          0,
          addresses[currNetwork].ERC721,
          addresses[currNetwork].USDC,
          artist_address
        )
        .send({ from: accounts.result[0] });

      return receipt;
    } else {

      receipt = await auctionContract.methods
        .createAuctionEth(
          auctionId,
          time,
          artwork_id,
          minimumBid * decimalsEth,
          0,
          addresses[currNetwork].ERC721,
          artist_address
        )
        .send({ from: accounts.result[0] });

    }
  } catch (error) {
    throw error.message;
  } 
};

const bid = async (auctionId, value) => {
  const accounts = await window.ethereum.send("eth_requestAccounts");
  window.web3 = new Web3(window.ethereum);

  console.log(auctionId);
  // Create contract object
  const tokenContractERCAuction = new window.web3.eth.Contract(
    GOGAuctionV2.abi,
    addresses[currNetwork].auction
  );
  const receipt = await tokenContractERCAuction.methods
    .bid(auctionId, (value * decimals))
    .send({ from: accounts.result[0] });

  return receipt;
};

const tokenAllowance = async (amount) => {
    const accounts = await window.ethereum.send("eth_requestAccounts");
    window.web3 = new Web3(window.ethereum);

    // Create contract object
    const USDCContract = new window.web3.eth.Contract(
        USDCAbi,
        addresses[currNetwork].USDC
    );

    const receipt = await USDCContract.methods
        .approve(addresses[currNetwork].auction, (amount * decimals))
        .send({ from: accounts.result[0] });

  return receipt;
}  

const checkBalance = async () => {
    const accounts = await window.ethereum.send("eth_requestAccounts");
    console.log(accounts);
    window.web3 = new Web3(window.ethereum);

    // Create contract object
    const USDCContract = new window.web3.eth.Contract(
        USDCAbi,
        addresses[currNetwork].USDC
    );

    const result = await USDCContract.methods
        .balanceOf(accounts.result[0])
        .call();


    const balance = result / decimals;
    console.log(balance);


  return balance;
}   

const isMinter = async () => {
  const accounts = await window.ethereum.send("eth_requestAccounts");
  console.log(accounts);
  window.web3 = new Web3(window.ethereum);

  // Create contract object
  const USDCContract = new window.web3.eth.Contract(
    FERC721,
    addresses[currNetwork].ERC721
  );

  const result = await USDCContract.methods
      .hasRole(window.web3.utils.keccak256("MINTER_ROLE"), accounts.result[0])
      .call();


  //const balance = result / decimals;
  console.log(window.web3.utils.keccak256("MINTER_ROLE"));


return result;
}   

const getTxReceipt = async (tx) => {
  try {
    const receipt = await window.web3.eth.getTransactionReceipt(tx);
    return receipt;
  } catch (error) {
    console.log(error);
  }
};

const getDecimal = (value) => {
   return window.web3.utils.hexToNumber(value);
}

const signData = async (message) => {
  const accounts = await window.ethereum.send("eth_requestAccounts");
  const from = accounts.result[0];
    console.log(accounts);
    window.web3 = new Web3(window.ethereum);
    var params = [from, message];
    var method = 'eth_signTypedData_v4';
    
    return new Promise((resolve, reject) => {
      window.web3.currentProvider.sendAsync(
        {
          method,
          params,
          from
        },
        (err, result) => {
          if (err) return reject(err);
          if (result.error) {
            reject(result.error.message)
          }


          //const recoveredAddress = sigUtil.recoverTypedSignature({data: JSON.parse(message), signature: result.result, version: sigUtil.SignTypedDataVersion.V4})

          resolve(result.result);
    
          
        }
      );
    })
    
}


//Offers

const createOffer = () => {

}

const acceptOffer = () => {

}

const rejectOffer = () => {

}

const cancelSale = () => {

}

export {
  testAuthentication,
  pinFile,
  pinJson,
  addOperatorERC721,
  addOperatorGOGAuction,
  mintNft,
  getTxReceipt,
  createAuction,
  cancelAuction,
  bid,
  toggleAuction,
  tokenAllowance,
  getDecimal,
  checkBalance,
  createOffer,
  rejectOffer,
  acceptOffer,
  cancelSale,
  mintBasket,
  approveBasket,
  approveNFT,
  transferNFT,
  mintVault,
  signData,
  getNftSupply,
  addMinterERC721,
  addWhitelistERC721,
  isMinter,
  getGasFee,
  transfer,
  getCurrentPrice,
  unlockNftNow
};
