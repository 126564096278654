import { firebase } from '@firebase/app'

import '@firebase/auth'
import '@firebase/firestore'
import '@firebase/storage'
import env from './config'
import api from '../axios/api'


firebase.initializeApp(env.firebaseConfig);


const db = firebase.firestore()
const auth = firebase.auth()
const storage = firebase.storage()
const authObject = firebase.auth

const getCurrentUser = () => {
    return new Promise((resolve, reject) => {
        const unsubscribe = auth.onAuthStateChanged(user => {
            console.log(user);
            unsubscribe();
            resolve(user);
        }, reject);
    })
};

const getAdmin = async (uid) => {
    const results = await api.get(`find_admin?ref=${uid}`);
    console.log(results.data);
    return results.data;
}

const getClientByBlockChain = async(address) => {
    const clientsRef = db.collection('clients');

    const metamask = clientsRef.where('metamask', '==', address).get();
    const fortmatic = clientsRef.where('formatic', '==', address).get();
    const portis = clientsRef.where('portis', '==', address).get();
    
    const [metamaskQuerySnapshot, fortmaticQuerySnapshot, portisQuerySnapshot] = await Promise.all([
        metamask,
        fortmatic,
        portis
      ]);

    let client = {
        data: {},
        dbRef: null,
        state: false
    };
    
    if (!metamaskQuerySnapshot.empty) {
        client.data = metamaskQuerySnapshot.docs[0].data();
        client.dbRef = metamaskQuerySnapshot.docs[0].id;
        client.state = true;
    } else if(!fortmaticQuerySnapshot.empty) {
        client.data = fortmaticQuerySnapshot.docs[0].data();
        client.dbRef = fortmaticQuerySnapshot.docs[0].id;
        client.state = true;
    } else if(!portisQuerySnapshot.empty) {
        client.data = portisQuerySnapshot.docs[0].data();
        client.dbRef = portisQuerySnapshot.docs[0].id;
        client.state = true;
    }

    return client;
}

const getLastBid = async (auction) => {
    const bidsNew = [];
    const result = await db.collection('bids')
        .where('auctionId', '==', auction)
        .get()

    result.docs.map((doc) => bidsNew.push(doc.data()))
    bidsNew.sort((a, b) => {
        const dateA = new Date(a.date).getTime();
        const dateB = new Date(b.date).getTime();
        return dateB - dateA;
    }); 

    return bidsNew[0];
}

const getAdmins = async () => {
    const results = await api.get('find_admins');
    
    return results.data;
}

const getStaff = async () => {
    const results = await api.get('findStaff');

    return results.data;
}

const inviteAdmins = async (data) => {

    

    const results = await api.post('create_admin', data);
    console.log(results.data.user);
    const dbRef = results.data.user;

    await firebase.auth().sendSignInLinkToEmail(data.email, {
        url: 'https://'+process.env.VUE_APP_ADMIN+'/register/'+dbRef,
        handleCodeInApp: true
      });

    return results.data;
}

const saveAdminProfile = async(data) => {
    const results = await api.post('edit_admin', data);

    return results.data;
}

const getCategories = async (data) => {
    const results = await api.get('find_categories', data);

    return results.data;
}

const newCategory = async (data) => {
    const results = await api.post('create_category', data);

    return results.data;
}


//Vaults

const getStats = async () => {
    const results = await api.get('stats');

    return results.data;
}

const getVaults = async() => {
    const results = await api.get('find_vaults');
    return results.data;
}

const getVault = async(dbRef) => {
    const results = await api.get('find_vault?ref='+dbRef);
    return results.data;
}

const updateVault = async(dbRef, data) => {
    const results = await api.post('edit_vault?ref='+dbRef, data);
    return results.data;
}


export {
    db,
    auth,
    authObject,
    storage,
    getCurrentUser,
    getClientByBlockChain,
    getLastBid,
    inviteAdmins,
    getAdmins,
    getStaff,
    newCategory,
    getCategories,
    getAdmin,
    saveAdminProfile,
    getStats,
    getVaults,
    getVault,
    updateVault
};