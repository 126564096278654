import { db, getCurrentUser, getAdmin, saveAdminProfile, auth } from '../../firebase/firebase';
import { addMinterERC721, addOperatorERC721, addOperatorGOGAuction } from '../../blockchain/index';

// initial state
const state = () => ({
  admin: {
    dbRef: null,
    unique: '',
    username: "",
    about: "",
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    country: "",
    street: "",
    city: "",
    state: "",
    zipCode: "",
    comments: false,
    candidates: false,
    offers: false,
    sms: "",
    firebaseUser: "",
    avatar: null,
    defaultOperatorAddress: null,
    isERC721Operator: false,
    isAuctionOperator: false
  }
});

const getDefaultState = () => {
  return {
    admin: {
      dbRef: null,
      unique: '',
      username: "",
      about: "",
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      country: "",
      street: "",
      city: "",
      state: "",
      zipCode: "",
      comments: false,
      candidates: false,
      offers: false,
      sms: "",
      firebaseUser: "",
      avatar: null,
      userType: 'admin',
      defaultOperatorAddress: null,
      isERC721Operator: false,
      isAuctionOperator: false
    }
  }
}

// getters
const getters = {
  getAdmin: (state) => {
    return state.admin;
  },
  getFullName: (state) => {
    console.log(state);
    return state.admin.firstName + ' ' + state.admin.lastName;
  },
  getAvatar: (state) => {
    return state.admin.avatar;
  },
  getEmail: (state) => {
    return state.admin.email;
  },
  hasCurrentAddress: (state) => {
    return state.admin.defaultOperatorAddress || false;
  }
}

// actions
const actions = {
    async getAdmin ({ dispatch, state }, payload) {
      if (!state.admin.dbRef) {
        if (payload.uuid) {
          dispatch('loadInvitedAdmin', payload);
        } else {
          const admin = await getCurrentUser();
          if (admin) {
            dispatch('recoverAdmin', admin);
          }
        }
      }
    },
    async loadInvitedAdmin ({commit}, payload) {
        const results = await getAdmin(payload.uuid);
        await auth.signInWithEmailLink(results.email, payload.url);

        console.log('admin=> ', results);
        
        const admin = results;
        admin.dbRef = payload.uuid;
        console.log('Admin =>', admin);
        commit('setAdmin', admin);
      
    },
    async recoverAdmin ({commit}, payload) {
      const results = await getAdmin(payload.uid);
      console.log('firebase user=> ', payload.email);
      let admin = Object.assign({}, {});
      if (results) {
        const admin = results;
        admin.dbRef = payload.uid;
        commit('setAdmin', admin);
      } else {
        admin.email = payload.email
        admin.dbRef = payload.uid;
        commit('setAdmin', admin);
      }

      return results;
      
    },
    async connectToBlockChain({commit}) {

      try {

        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        commit('setBlockchain', accounts[0]);

     } catch (error) {
       console.log(error);
     }
    },

    async disconnectFromBlockChain({commit}) {

      try {

        commit('removeBlockChain');

     } catch (error) {
       console.log(error);
     }
    },
    async saveAdmin ({commit}, payload) {

        try {
        // Get user account wallet address first

        const admin = payload.admin;
       

        await saveAdminProfile(payload.admin);
        commit('setAdmin', admin);

      } catch (error) {
        throw new Error(error.message);
      }
      
    },
    async makeERC721Operator({commit}, payload) {

      try {
        const receipt = await addOperatorERC721(payload.address);
        console.log(receipt);
        await db.collection("admins").doc(payload.ref)
        .update({
          isERC721Operator: true 
        });

        commit('setERC721OperatorStatus');
      } catch (error) {
        console.log(error)
      }

    },

    async makeAuctionOperator({commit}, payload) {

      try {
        const receipt = await addOperatorGOGAuction(payload.address);
        console.log(receipt);
        await db.collection("admins").doc(payload.ref)
        .update({
          isAuctionOperator: false 
        });
        commit('setAuctionOperatorStatus');

      } catch (error) {
        console.log(error)
      }

      

    },
    async makeMinter({commit}, payload) {

      try {
        const receipt = await addMinterERC721(payload.address);
        console.log(receipt);
        await db.collection("admins").doc(payload.ref)
        .update({
          isAuctionOperator: false 
        });
        commit('setAuctionOperatorStatus');

      } catch (error) {
        console.log(error)
      }

      

    },
    async logoutAdmin ({ commit }) {
      await auth.signOut();
      await delete('admin');
      commit('resetState');
    }
    
  }

// mutations
const mutations = {
    setAdmin (state, payload) {
      state.admin = Object.assign({}, payload);
    },
    setBlockchain (state, payload) {
      const admin = state.admin;
      admin.defaultOperatorAddress = payload;

      state.admin = Object.assign({}, admin);
    },
    removeBlockchain (state) {
      const admin = state.admin;
      admin.defaultOperatorAddress = null;

      state.admin = Object.assign({}, admin);
    },
    resetState (state) {
      Object.assign(state, getDefaultState())
    },
    setERC721OperatorStatus(state) {
      const admin = state.admin;
      admin.admin.isERC721Operator = true;

      state.admin = Object.assign({}, admin);
    },
    setAuctionOperatorStatus(state) {
      const admin = state.admin;
      admin.admin.isAuctionOperator = true;

      state.admin = Object.assign({}, admin);
    }
  }


export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}