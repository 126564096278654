import { db } from "../../firebase/firebase";

// initial state
const state = () => ({
    offer: baseOffer,
});
  
const baseOffer = {
    from: '',
    fromAddress: '',
    amount: 0,
    currency: '',
    date: '',
    status: '',
    expiring_date: ''
};

const getters = {
    offer(state) {
        return state.offer
    }
}

const mutations = {
    setOffer(state, payload) {
        state.offer = payload;
    }
}

const actions = {
    async getOffer({ commit }, payload) {
        const offer = await db
        .collection("offers")
        .doc(payload.offerRef)
        .get();

        if (offer.exists) {
            commit("setOffer", offer);
        }
    },

    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };