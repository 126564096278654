import { db } from "../../firebase/firebase";

// initial state
const state = () => ({
    sale: baseSale,
});
  
const baseSale = {
    saleRef: null,
    from: 'GOG Auction',
    fromAddress: '0x7dd22a207f91be314bacb54c743cd22299962458',
    soldTo: null,
    soldToAddress: null,
    amount: 0,
    currency: '',
    expiring_date: '',
    date: '',
    status: '',
    transactionHash: ''
};

const getters = {
    sale(state) {
        return state.sale
    },
    saleRef(state) {
        return state.sale.saleRef;
    }
}

const mutations = {
    setSale(state, payload) {

        state.sale = payload;
    }
}

const actions = {
    async getSale({ commit }, payload) {
        const sale = await db
        .collection("sales")
        .doc(payload.saleRef)
        .get();

        if (sale.exists) {
            commit("setSale", sale);
        }
    },

    async createSale({ commit }, payload) {
        console.log('Sale Store ->', payload);
        let nft = await db
            .collection("nfts")
            .doc(payload.nft)
            .get();
        
        let sales = nft.data().sales || [];

        const payloadSale = {
            from: payload.from,
            fromAddress: payload.fromAddress,
            amount: payload.total,
            date: new Date().toISOString(),
            expiring_date: payload.exp_date,
            nft: payload.nft,
            status: 'active',
            currency: payload.currency,
            soldTo: null,
            soldToAddress: null,
            transactionHash: null
        };

        const sale = await db
        .collection("sales")
        .add(payloadSale);

        
        sales.push(sale.id);

        await db
        .collection("nfts")
        .doc(payload.nft)
        .update({ sales: sales });

        //dispatch("user/newSale", {sale: sale.id}, { root: true });

        commit("setSale", {saleRef: sale.id, ...payloadSale});
    
    },

    
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
  };